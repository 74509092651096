import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Button, ButtonVariant } from "brass-ui-kit";

import * as styles from "./Hero.module.scss";
import { APP_SIGNUP_URL } from "data/config";

import { CustomVideoPlayer } from "components/design-system";
import { Play, TechCrunch, BusinessInsider, Yahoo } from "assets/media/svgs";
import OpenAccountButton from "components/design-system/OpenAccountButton/OpenAccountButton";

const Hero: React.FC = () => {
  const [showVideoPlayer, setShowVideoPlayer] = React.useState(false);

  React.useEffect(() => {
    if (showVideoPlayer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showVideoPlayer]);

  return (
    <section className={styles.Hero}>
      <div className={cx(styles.Hero_container)}>
        <div className={cx("grid-span-1-6", styles.Hero_content)}>
          <header className={styles.Hero_header}>
            <h1 className={styles.Hero_header_title}>
              Banking that works for your business
            </h1>
            <p className={styles.Hero_header_body}>
              Do more with a business account that comes with top-end financial
              tools and integrations to help grow your business.
            </p>
            <OpenAccountButton className={styles.Hero_header_link} />
          </header>
          <section className={styles.Hero_media}>
            <a
              href="
            https://techcrunch.com/2021/10/21/ventures-platform-hustle-fund-back-nigerian-fintech-brass-in-1-7m-round/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TechCrunch />
            </a>
            <a
              href="
              https://www.yahoo.com/now/ventures-platform-hustle-fund-back-080006151.html?guccounter=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Yahoo />
            </a>
            <a
              href="
              https://africa.businessinsider.com/local/markets/nigerian-fintech-brass-secures-dollar17mn-financing-round-to-power-expansion-into/74mwmjq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BusinessInsider />
            </a>
          </section>
        </div>
        <div className={cx("grid-span-7-6", styles.Hero_image)}>
          <StaticImage
            as="span"
            alt="hero"
            loading="eager"
            src="../../../assets/media/images/home/hero.png"
          />
          <div
            className={styles.Hero_image_action}
            onClick={() => {
              setShowVideoPlayer(true);
            }}
          >
            <Play />
            <div>
              <h4>Play this video</h4>
              <p className={styles.Hero_image_action_sub}>
                To see why we built brass
              </p>
            </div>
          </div>
        </div>
      </div>
      {showVideoPlayer && (
        <CustomVideoPlayer
          handleClose={() => {
            setShowVideoPlayer(false);
          }}
          source="vimeo"
          videoID="637218269?h=2a0f8b8a46"
          show={showVideoPlayer}
        />
      )}
    </section>
  );
};

export default Hero;
