import * as React from "react";
import { PageProps, graphql } from "gatsby";

import {
  FaqWidget,
  GetStarted,
  Resources,
  Support,
  Banner,
} from "components/design-system";
import SEO from "components/SEO";
import {
  Hero,
  Features,
  Qualities,
  Integrations,
  Testimonials,
} from "components/home";
import pagesMeta from "data/pagesMeta";

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  return (
    <>
      <SEO {...pagesMeta.home} />

      {/* <Banner
        title="Introducing Brass Payroll: The Ultimate Payroll Solution for Your
          Business"
        path="payroll"
      /> */}

      <Hero />

      <Qualities />

      <Features />

      <Testimonials />

      <Integrations />

      <Support items={pageData.supportItems} />

      <Resources
        align="left"
        overflow={true}
        items={pageData.resources}
        title="Go further with resources to get started"
        description="We have partnered with businesses on the same mission as ours to help local businesses. We have also added resources you need to start, run and grow your business."
      />

      <FaqWidget content={pageData.faqContent} />

      <GetStarted />
    </>
  );
};

export const query = graphql`
  query IndexPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "home" } } }
    ) {
      edges {
        node {
          frontmatter {
            supportItems {
              description
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            resources {
              link
              title
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }

            faqContent {
              answer
              question
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
